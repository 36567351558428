import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { grid } from 'ldrs';
import { formatSpeed } from 'utils';
import {
  UserWorkerQuery,
  UserWorkersQuery,
  WorkerBaseInfo,
} from 'gql/pool/__generated__/request';
import moment from 'moment';
import Loading from 'components/Loading';
import { poolRequestApi } from 'gql';
import user from 'stores/user';
import { RowCenter } from 'components/Row';

const Wrapper = styled.div`
  width: 100%;
  min-width: 600px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    min-width: unset;
    background: rgba(0, 0, 0, 0.3);
    `};
`;

const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;

const SwtichItem = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #0e0905;
  opacity: 0.5;
  cursor: pointer;

  &.onSelected {
    opacity: 1;
    font-weight: bold;
  }
`;

interface Props {
  userId?: string;
  workerName: string;
  isShow?: boolean;
}
export default function SpeedChart({ userId, workerName, isShow }: Props) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<UserWorkerQuery>();

  async function getUserWorkerInfo() {
    setIsLoading(true);
    const date = new Date();
    const minutes = date.getMinutes();
    const roundedMinutes = Math.floor(minutes / 10) * 10;
    date.setMinutes(roundedMinutes);
    const res = await poolRequestApi.userWorker({
      userId: userId || '',
      workerName,
      startTime: moment(date).add('days', -1).valueOf(),
    });
    if (res) {
      setData(res);
    }
    setIsLoading(false);
    return res;
  }

  useEffect(() => {
    if (isShow) {
      getUserWorkerInfo();
    }
  }, [isShow, workerName, userId]);

  const chartData: {
    x: number[];
    speed: number[];
    localSpeed: number[];
    speedAverage: number;
    localSpeedAverage: number;
  } = useMemo(() => {
    if (!data) {
      return {
        x: [],
        speed: [],
        localSpeed: [],
        speedAverage: 0,
        localSpeedAverage: 0,
      };
    }

    const poolComputeValuesCharts =
      data.userWorker?.workerCharts.poolComputeValuesCharts || [];
    const localComputeValuesCharts =
      data.userWorker?.workerCharts.localComputeValuesCharts || [];
    const useFirst =
      poolComputeValuesCharts.length >= localComputeValuesCharts.length;
    const x = [];
    const speed = [];
    const localSpeed = [];
    const indexArray = useFirst
      ? poolComputeValuesCharts
      : localComputeValuesCharts;
    for (let i = 0; i < indexArray.length; i++) {
      x.push(indexArray[i].timestamp);
      speed.push(poolComputeValuesCharts[i]?.value || 0);
      localSpeed.push(localComputeValuesCharts[i]?.value || 0);
    }

    return {
      x,
      speed,
      localSpeed,
      speedAverage:
        speed.reduce(function (acr, cur) {
          return acr + cur;
        }) / indexArray.length,
      localSpeedAverage:
        localSpeed.reduce(function (acr, cur) {
          return acr + cur;
        }) / indexArray.length,
    };
  }, [data]);

  const options = useMemo(() => {
    const tooltips = {
      trigger: 'axis',
      formatter: function (params: any) {
        let tip = '';

        if (params != null && params.length > 0) {
          tip +=
            moment(Number(params[0].name)).format('MM-DD HH:mm') + '<br />';
          for (let i = 0; i < params.length; i++) {
            if (i !== 0) {
              tip += '<br />';
            }
            let value = params[i].value;
            tip += `${params[i].marker} ${params[i].seriesName}: ${formatSpeed(
              value,
            )} <br />${params[i].marker} ${t('Average')}: ${
              params[i].seriesName === t('Speed(Estimated)')
                ? formatSpeed(chartData.speedAverage)
                : formatSpeed(chartData.localSpeedAverage)
            }<br />`;
          }
        }
        return tip;
      },
    };
    return {
      tooltip: isMobile ? undefined : tooltips,
      grid: {
        x: 60,
        y: 30,
        x2: 20,
        y2: 40,
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: chartData.x,

        axisLabel: {
          formatter: (value: string) => {
            return `${moment(Number(value)).format('MM-DD')}\n${moment(
              Number(value),
            ).format('HH:mm')}`;
          },
        },
      },
      legend: {
        data: [t('Speed(Estimated)'), t('Local Speed')],
        textStyle: {
          color: '#fff',
        },
        right: 0,
        icon: 'circle',
        itemWidth: 10,
        itemHeight: 10,
      },
      yAxis: {
        name: t('Last 24 hours speed'),
        splitLine: {
          lineStyle: {
            color: 'rgba(217, 217, 217, 0.2)',
          },
        },
        axisLabel: {
          formatter: function (value: number) {
            // y轴自定义数据
            return formatSpeed(value);
          },
        },
      },
      series: [
        {
          // spee
          name: t('Speed(Estimated)'),
          type: 'line',
          showSymbol: false,
          smooth: true,
          data: chartData.speed,

          lineStyle: {
            color: '#22EEE2',
          },
          itemStyle: {
            color: '#22EEE2',
          },
          areaStyle: {
            opacity: 0.8,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: 'rgba(34, 238, 226, 0.2)',
              },
              {
                offset: 1,
                color: 'rgba(34, 238, 226, 0)',
              },
            ]),
          },
          markLine: {
            symbol: ['none', 'none'],
            data: [
              {
                type: 'average', // 设置线类型为平均线
                lineStyle: {
                  normal: {
                    color: '#22EEE2', // 设置线的颜色为绿色
                    width: 1, // 设置线的宽度为2px
                    type: 'dashed', // 设置线的类型为实线
                  },
                },
                label: {
                  formatter: '',
                },
              },
            ],
          },
        },
        {
          name: t('Local Speed'),
          // localSpeed
          type: 'line',
          showSymbol: false,
          smooth: true,
          data: chartData.localSpeed,

          lineStyle: {
            color: '#7C3AED',
          },
          itemStyle: {
            color: '#7C3AED',
          },
          areaStyle: {
            opacity: 0.8,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: 'rgba(124, 58, 237, 0.2)',
              },
              {
                offset: 1,
                color: 'rgba(124, 58, 237, 0)',
              },
            ]),
          },
          markLine: {
            symbol: ['none', 'none'],
            data: [
              {
                type: 'average', // 设置线类型为平均线
                lineStyle: {
                  normal: {
                    color: '#7C3AED', // 设置线的颜色为绿色
                    width: 1, // 设置线的宽度为2px
                    type: 'dashed', // 设置线的类型为实线
                  },
                },
                label: {
                  formatter: '',
                },
              },
            ],
          },
        },
      ],
    };
  }, [data]);

  return (
    <Wrapper>
      {isLoading ? (
        <RowCenter>
          <Loading />
        </RowCenter>
      ) : (
        <ReactECharts
          option={options}
          notMerge={true}
          lazyUpdate={true}
          style={{ width: '100%', height: 200 }}
          // onChartReady={this.onChartReadyCallback}
          // onEvents={EventsDict}
          // opts={}
        />
      )}
    </Wrapper>
  );
}
