import React, { useEffect, useMemo, useState } from 'react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Row from 'components/Row';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Button from 'components/Button';

import styled, { createGlobalStyle } from 'styled-components';

import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';
import storage from 'utils/storage';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TYPE } from 'theme';
import SearchIcon from '@mui/icons-material/Search';
import { SearchSVG } from 'assets';
import PageTitle from 'components/PageTItle';
import { poolRequestApi } from 'gql';
import classNames from 'classnames';
import alert from 'stores/alert';
import JoinContent from 'components/JoinContent';
import { isMobile } from 'react-device-detect';
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  border-radius: 4px;
  background: transparent;
  width: 100%;
  box-sizing: border-box;
  gap: 20px;
  z-index: 1;

  .title {
    display: none;
  }
  .background {
    display: none;
  }
  .history {
    max-height: 0;
    overflow: hidden;
  }

  &.active {
    position: fixed;
    top: 0;
    width: 100vw;
    padding-top: 100px;

    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;

    .title {
      display: block;
      z-index: 1000000;
      margin-top: 40px;
    }

    .background {
      display: block;
    }

    .inputRow {
      z-index: 100000;
      /* top: 200px; */
      width: 1000px;
      transform: translateY(40px);
      transition: transform 0.2s ease-in;
      ${({ theme }) => theme.mediaWidth.upToSmall`
      width: calc(100% - 20px);
      `};

      /* animation: fadeIn 0.3s ease-out forwards;
      @keyframes fadeIn {
        from {
          transform: translate(-50%, 0);
        }
        to {
          transform: translate(-50%, 10px);
        }
      } */
    }
    .history {
      transform: translateY(40px);
      transition: all 0.2s ease-in;
      max-height: calc(100vh - 380px);
      ${({ theme }) => theme.mediaWidth.upToSmall`
       max-height: calc(100vh - 100px);
      `};
      overflow-y: scroll;
    }
  }

  .MuiTextField-root {
    padding-right: 10px;
    padding-left: 20px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    padding-left: 0;
    `};
    .MuiAutocomplete-inputRoot {
    }
    input {
      font-size: 20px;
      padding-right: 10px !important;

      color: ${({ theme }) => theme.c.t02};
      ${({ theme }) => theme.mediaWidth.upToSmall`
         font-size: 20px;
        `};
      &::placeholder {
        ${({ theme }) => theme.mediaWidth.upToSmall`
            font-size: 18px;
            `};
      }
    }
    fieldset {
      border: none;
    }
    .MuiFormLabel-root {
      display: none;
    }
    width: 100%;
    input {
      color: ${({ theme }) => theme.c.t01};
    }

    .MuiInputBase-root {
      color: ${({ theme }) => theme.c.t01};
    }
  }
`;

const InputRow = styled(Row)`
  width: 100%;
  margin-top: 24px;
  height: 88px;
  padding-right: 20px;
  align-items: center;
  box-sizing: border-box;
  font-size: 18px !important;
  font-weight: bold !important;
  border-radius: 20px;

  border: 1px solid #537178;

  &.error {
    border: 1px solid red;
  }
  justify-content: space-between;
  background: linear-gradient(
    145deg,
    rgba(51, 63, 61, 1) 0%,
    #191919 15%,
    #191919 85%,
    rgba(127, 67, 229, 0.35) 100%
  );

  input {
    width: 100%;
    padding: 0px;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 60px;
    padding: 8px 16px;
    margin-top: 8px;
    `}/* border-bottom: 1px solid rgba(7, 221, 208, 0.2); */
`;

const StyledSearchButton = styled(Button)`
  font-family: 'gemsbuck-title', 'Montserrat', sans-serif !important;
  text-transform: uppercase !important;
  flex-shrink: 0;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  height: 42px !important;
  font-size: 14px !important;
  transform: unset;
  padding: 0 10px !important;
  `};
`;
const HistoryWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 1000px;
  gap: 8px;
  z-index: 99999;
  ${({ theme }) => theme.mediaWidth.upToSmall`
   width: calc(100% - 20px);
  `};
`;
const HistoryItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  padding: 0px 20px;
  border-radius: 12px;
  flex-direction: row;
  font-size: 14px;
  cursor: pointer;
  flex-shrink: 0;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  padding: 0 10px;
  `};
  .text {
    max-width: 90%;
    word-wrap: break-word;
  }

  svg {
    width: 1rem;
    height: 1rem;
  }
`;

const StyledHighlightOffIcon = styled(HighlightOffIcon)`
  cursor: pointer;
  color: #fff;
  width: 30px;
  height: 30px;
  flex-shrink: 0;
`;

const StyledSearchIcon = styled(SearchSVG)`
  width: 27px;
  height: 27px;
  color: ${({ theme }) => theme.c.t02};
  margin-right: 10px;
`;

const Background = styled.div`
  position: fixed;
  z-index: 99999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
`;

const ErrorWrapper = styled.div`
  width: 1000px;
  margin-top: 100px;
  z-index: 100000;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
   width: calc(100% - 20px);
  `};
`;

const StyledJoinContent = styled(JoinContent)`
  gap: 30px;
`;

interface Props {
  active?: boolean;
  onCompleteSearch?(success?: boolean): void;
  handleActive?(active: boolean): void;
}

const SearchMinerHistory = 'SearchMinerHistory-v1';
export default function SearchMiner({
  active,
  onCompleteSearch,
  handleActive,
}: Props) {
  // const balance  = useBalance(tokenAddress)
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(active);
  const [historySearch, setHistory] = useState<string[]>(
    storage.get(SearchMinerHistory) || [],
  );
  //   const [errorMessage, setErrorMessage] = useState('');
  const [input, setInput] = useState('');
  const history = useHistory();
  const [isError, setIsError] = useState(false);

  async function search(value: string) {
    if (value) {
      try {
        const res = await poolRequestApi.userInfo({
          userId: value,
          _notCatchedBefore: true,
        } as any);
        if (res) {
          addHistory(value);
          history.push(`/machine/${value}`);

          if (onCompleteSearch) {
            onCompleteSearch(true);
          }
          setIsActive(false);
        }
      } catch (e) {
        console.error(e);
        setIsError(true);
        alert.error({
          text: t('Miner does not exist'),
        });
      }
    }
  }

  function onChangeValue(value?: string) {
    if (!value) {
      setInput('');
    } else {
      setInput(value);
    }
  }

  function addHistory(value?: string) {
    if (value && !historySearch.includes(value)) {
      historySearch.push(value);
      const res = Array.from(new Set(historySearch));
      storage.set(SearchMinerHistory, res);
      setHistory(res);
    }
  }

  function removeHistory(value: string) {
    const res = historySearch.filter((item) => item !== value);
    storage.set(SearchMinerHistory, res);
    setHistory(res);
  }

  const filterSearch = useMemo(() => {
    return historySearch.filter((item) => item.includes(input));
  }, [search, historySearch]);

  useEffect(() => {
    if (handleActive) {
      handleActive(!!isActive);
    }
  }, [isActive, handleActive]);

  function clickOutsideOfInput() {
    setIsActive(false);
    setIsError(false);
    setInput('');
    if (onCompleteSearch) onCompleteSearch();
  }

  return (
    <>
      <Wrapper className={isActive ? 'active' : ''}>
        <PageTitle className="title" />
        <Background onClick={clickOutsideOfInput} className="background" />
        <InputRow
          align="between"
          className={classNames('inputRow', isError ? 'error' : '')}
          onClick={() => {
            if (!isActive) {
              setIsActive(true);
            }
          }}
        >
          <TextField
            placeholder={t('Enter Miner Address')}
            className={'input'}
            value={input}
            variant="outlined"
            onChange={(e) => {
              if (isError) {
                setIsError(false);
              }
              onChangeValue(e.currentTarget.value);
            }}
            label=""
            InputProps={{
              startAdornment: !isMobile && <StyledSearchIcon />,
              endAdornment: input ? (
                <StyledHighlightOffIcon
                  onClick={() => {
                    setInput('');
                    setIsError(false);
                  }}
                />
              ) : null,
            }}
          />
          <StyledSearchButton
            disabled={!input}
            onClick={async () => {
              await search(input);
            }}
          >
            {t('Search')}
          </StyledSearchButton>
        </InputRow>

        {isError ? (
          <ErrorWrapper onClick={clickOutsideOfInput}>
            {isError && (
              <TYPE.gMain fontSize={48} mobileFz={24}>
                {t('Address not found')}
              </TYPE.gMain>
            )}
            <StyledJoinContent />
          </ErrorWrapper>
        ) : (
          <HistoryWrapper className="history">
            {!!filterSearch.length && (
              <HistoryItem>
                <TYPE.desc fontSize={16} uppercase fontWeight={600}>
                  {t('Search History')}
                </TYPE.desc>
              </HistoryItem>
            )}

            {filterSearch.map((item, index) => {
              return (
                <HistoryItem
                  key={index}
                  className="searchLi"
                  onClick={async () => {
                    history.push(`/machine/${item}`);
                    if (onCompleteSearch) {
                      onCompleteSearch(true);
                    }
                    setIsActive(false);
                  }}
                >
                  <TYPE.desc fontSize={16} className="text">
                    {item}
                  </TYPE.desc>
                  <DeleteForeverIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      removeHistory(item);
                    }}
                  />
                </HistoryItem>
              );
            })}
          </HistoryWrapper>
        )}
      </Wrapper>
    </>
  );
}
