import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { TYPE } from 'theme';
import { LanguageSVG } from 'assets';
import { isMobile } from 'react-device-detect';
import Row, { RowBetween } from 'components/Row';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { SelectedSVG } from 'assets';
const LanguageButton = styled(Button)`
  color: #fff;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  padding: 0 !important;
`;

const Languages = {
  en: 'English',
  'zh-CN': '中文',
  //   jp: '日本語',
  //   ru: 'Русский',
  //   kr: '한국인',
};

const MobileWrapper = styled.div`
  width: 100%;
`;

const MobileLanguageSelectWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  position: absolute;
  top: 0;
  z-index: 9999;
  bottom: 0;
  left: 0%;
  right: 0;
  background: #000;
  gap: 30px;
  padding: 0 20px;
`;

export default function BasicMenu({
  className,
  onCloseMobileNav,
}: {
  className?: string;
  onCloseMobileNav?: () => void;
}) {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const [isOpenNav, setIsOpenNav] = React.useState(false);
  const handleClose = (lang = 'en') => {
    setAnchorEl(null);
    i18n.changeLanguage(lang);
  };

  return isMobile ? (
    <MobileWrapper>
      <RowBetween onClick={() => setIsOpenNav(true)}>
        <TYPE.main
          style={{
            textTransform: 'none',
          }}
        >
          {t('Language')}
        </TYPE.main>
        <TYPE.main
          color="#ccc"
          style={{
            display: 'flex',
            alignItems: 'center',
            textTransform: 'none',
          }}
        >
          {(Languages as any)[i18n.language]}
          <KeyboardArrowRightIcon />
        </TYPE.main>
      </RowBetween>
      {isOpenNav && (
        <MobileLanguageSelectWrapper>
          <Row>
            <TYPE.main
              onClick={() => setIsOpenNav(false)}
              style={{
                display: 'flex',
                alignItems: 'center',
                textTransform: 'none',
              }}
              fontWeight={600}
            >
              <KeyboardArrowLeftIcon />

              {t('Language')}
            </TYPE.main>
          </Row>
          {Object.keys(Languages).map((item, index) => (
            <Row
              onClick={() => {
                handleClose(item);
                onCloseMobileNav();
              }}
              style={{
                justifyContent:
                  i18n.language === item ? 'space-between' : 'flex-start',
              }}
            >
              <TYPE.main key={index}>{(Languages as any)[item]}</TYPE.main>
              {i18n.language === item && <SelectedSVG />}
            </Row>
          ))}
        </MobileLanguageSelectWrapper>
      )}
    </MobileWrapper>
  ) : (
    <div className={className}>
      <LanguageButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <TYPE.main
          style={{
            textTransform: 'none',
          }}
        >
          {(Languages as any)[i18n.language]}
        </TYPE.main>
        <LanguageSVG />
      </LanguageButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          handleClose();
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {Object.keys(Languages).map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              handleClose(item);
            }}
          >
            {(Languages as any)[item]}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
