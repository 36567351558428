import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import { NavLink, useHistory, useLocation } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';

import styled, { createGlobalStyle } from 'styled-components';
import { observer } from 'mobx-react';

import { TYPE } from 'theme';
import { Drawer } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { FireImg, Logo } from 'assets';
import Grid from '@mui/material/Grid';
import Language from './language';
import { useTranslation } from 'react-i18next';
import { isVIP, TUTORIAL_LINK } from 'const';
import Row from 'components/Row';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { isMobile } from 'react-device-detect';

const HeaderWapper = styled.div`
  position: fixed;
  z-index: 88;
  left: 0;
  top: 0;
  width: 100%;
  height: 80px;
  background-color: transparent;

  // box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  font-size: 20px;
  /* background: ${({ theme }) => theme.c.bg01}; */

  &.showBg {
    background: #000;

    transition: all 0.3s linear;
  }

  .logoContainer {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    cursor: pointer;

    img {
      width: 112px;
    }

    .logoText {
      font-size: 18px;
      color: ${({ theme }) => theme.c.t01};
      font-weight: 800;
      margin-left: 20px;
    }
  }
  .content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0 80px;

    ${({ theme }) => theme.mediaWidth.upToSmall`
        padding: 0
        `}
  }

  .navWrapper {
    display: none;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
        width: 100%;
        padding: 0 1rem;
        box-sizing: border-box;

        .content {
            width: 100%;

            .menu-icon {
                display: block;
                position: relative;
                width: 24px;
                height: 24px;
                font-size: 0;
                z-index: 2;

                &::before,
                &::after {
                    display: block;
                    position: absolute;
                    left: 2px;
                    content: '';
                    width: 20px;
                    height: 2px;
                    background: $pink;
                    transition: 0.3s;
                    cursor: pointer;
                }

                &::before {
                    top: 6.5px;
                }

                &::after {
                    top: 14.5px;
                }
            }

            .right {
                display: none;
                flex-direction: column;
                justify-content: center;
                position: fixed;
                z-index: -1;
                top: 0;
                right: 0;
                width: 80vw;
                height: 100vh;
                font-size: 0;
                opacity: 0;
                transition: 0.3s;
                border-left: 4px solid $pink;

                .right-bg {
                    display: block;
                    position: fixed;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    background: transparent;
                    z-index: -1;
                }

                &:after {
                    content: '';
                    position: absolute;
                    display: block;
                    width: 100%;
                    height: 100%;
                    background: $lightDark;
                    opacity: 0.8;
                    z-index: -1;
                }

                .nav-container {
                    flex: initial;
                    width: 100%;
                    height: initial;

                    .nav-list {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        padding-left: 0;
                        margin-top: -100px;
                        width: 100%;

                        .nav-item {
                            margin-bottom: 20px;
                            padding: 0;
                            width: 100%;
                            text-align: center;
                            font-size: 1.6rem;
                            background-color: transparent;

                            &.profile {
                                display: block;
                            }
                        }
                    }
                }
            }
        }

        &.open {
            .menu-icon {
                &::before,
                &::after {
                    top: 10px;
                }

                &::before {
                    transform: rotate(45deg);
                }

                &::after {
                    transform: rotate(-45deg);
                }
            }

            .right {
                display: flex;
                opacity: 1;
                z-index: 1;
            }
        }
    `}
`;

const MobileWrapper = styled.div`
  display: none;
  ${({ theme }) => theme.mediaWidth.upToSmall`
        position: fixed;
        top: 80px;
        z-index: 10;
        bottom: 0;
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        box-sizing: border-box;
        padding: 8px 4px;
        background: #000;
        gap: 30px;
        padding: 0 20px;

        a {
            height: fit-content;
            font-weight: 600;
            position: relative;

           
        }
  `}
`;

const NavWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 0 200px;
  padding-right: 210px;
  height: 100%;
  gap: 40px;
  flex-direction: row;
  /* background-image: url(${require('assets/img/header-nav-bg.png')}); */
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: 100%;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 200px;
    flex-direction: column;
    padding: 40px 0;
    height: fit-content;
    `};
`;
const Nav = styled(NavLink)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;
  position: relative;
  text-decoration: none;
  cursor: pointer;
  transition: transform 0.3s linear;
  &.active {
    &.isNotHome {
      div {
        color: #fff;
        background: unset;
        -webkit-background-clip: unset;
        -webkit-text-fill-color: unset;
      }
    }
    div {
      background: linear-gradient(
        60.31455181618791deg,
        #1dc5da 0%,
        #58297b 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .line {
      display: block;
      position: absolute;
      bottom: -20px;
      width: 50%;
      left: 50%;
      transform: translateX(-50%);
      height: 3px;
      background: #0e0905;
      border-radius: 2px;
    }
  }

  .line {
    display: none;
  }
`;

const RightWrpapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  display: none;
  `};
  .language {
    ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
    `};
  }
  /* ${({ theme }) => theme.mediaWidth.upToSmall`
  display: none;
  `}; */
`;

const NavOuterLink = styled.a`
  height: 100%;
  display: flex;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  color: #fff;
  position: relative;
  text-decoration: none;
  cursor: pointer;
  transition: transform 0.3s linear;
  text-decoration: none;
  flex-direction: row;
  gap: 4px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  width: 100%;
  justify-content: space-between;
  `};
  .arrow {
    display: none;
    color: #ccc;
    width: 20px;
    height: 20px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
       display: block;
    `};
  }

  &:hover {
    color: #22eee2;
  }

  img {
    width: 20px;
    height: 20px;
  }

  &.uppercase {
    text-transform: uppercase;
  }
`;

// const StyledOuterlinkSVG = styled(OuterlinkSVG)``;

const Menu = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  display: none;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: flex;
    `};

  svg {
    color: #fff;
    width: 30px;
    height: 30px;
  }
`;

const StyledDrawer = styled(Drawer)``;

const GlobalStyle = createGlobalStyle`
    .MuiDrawer-paper{
        background: #000;

    }
`;

const StyledLogo = styled.img`
  width: 180px;
  cursor: pointer;
`;

interface Props {
  showConnectButton?: boolean;
  connected?: boolean;
  onClickConnect?(): void;
  onClickDisconnect?(): void;
  address?: string;
  showLanguage?: boolean;
  notSupportedChain?: boolean;
}

function Header(props: Props) {
  const {
    // connected,
    // onClickConnect,
    showLanguage,
    // notSupportedChain,
  } = props;
  const history = useHistory();
  const [headerBgShow, setHeaderBgShow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { pathname } = useLocation();
  const { t } = useTranslation();
  useEffect(() => {
    const dom: Element | null = document.querySelector('.container');
    const scroll = () => {
      if (dom) {
        if (dom.scrollTop >= 40) {
          setHeaderBgShow(true);
        } else {
          setHeaderBgShow(false);
        }
      }
    };
    if (dom) {
      dom.addEventListener('scroll', scroll);
    }

    return () => {
      if (dom) {
        dom.removeEventListener('scroll', scroll);
      }
    };
  }, []);

  const isHome = useMemo(() => {
    return pathname === '/';
  }, [pathname]);

  const Navs = () => (
    <>
      {/* {!isVIP && (
        <NavOuterLink
          href={'https://testnet.cysic.xyz/m/aleopool'}
          target="_blank"
          onClick={() => {
            if (isMobile) {
              setIsOpen(false);
            }
          }}
        >
          <Row style={{ gap: '4px' }}>
            <img src={FireImg} />
            {t('Reduce Pool Fee')}
          </Row>
          <ArrowOutwardIcon className="arrow" />
        </NavOuterLink>
      )}
      <NavOuterLink
        href={TUTORIAL_LINK}
        target="_blank"
        onClick={() => {
          if (isMobile) {
            setIsOpen(false);
          }
        }}
      >
        {t('Join')}
        <ArrowOutwardIcon className="arrow" />
      </NavOuterLink> */}
      {/* <Nav id="home" to="/" className={isHome ? '' : 'isNotHome'}>
        <TYPE.main fontWeight={600}>Home</TYPE.main>
        <div className="line"></div>
      </Nav>
      <Nav id="home" to="/help" className={isHome ? '' : 'isNotHome'}>
        <TYPE.main fontWeight={600}>Help</TYPE.main>
        <div className="line"></div>
      </Nav> */}
      {/* <Nav
        id="product"
        to="/product"
        className={(isActive) => (isActive ? 'active' : '')}
      >
        <TYPE.main fontWeight={600}>Product</TYPE.main>
        <div className="line"></div>
      </Nav>
      <Nav
        id="company"
        to="/company"
        className={(isActive) => (isActive ? 'active' : '')}
      >
        <TYPE.main fontWeight={600}>Company</TYPE.main>
        <div className="line"></div>
      </Nav>
      <Nav
        id="writing"
        to="/writing"
        className={(isActive) => (isActive ? 'active' : '')}
      >
        <TYPE.main fontWeight={600}>Writing</TYPE.main>
        <div className="line"></div>
      </Nav>

      <Nav
        id="explorer"
        to="/explorer"
        className={(isActive) => (isActive ? 'active' : '')}
      >
        <TYPE.main fontWeight={600}>Explorer</TYPE.main>
        <div className="line"></div>
      </Nav> */}

      {/* {user.chainId !== ChainId.mainnet && (
                <NavOuterLink
                    href="https://t.me/ENKI_testnet_bot"
                    target="_blank"
                    rel="noreferrer"
                >
                    <TYPE.main>Faucet</TYPE.main>
                </NavOuterLink>
            )} */}
      {/* <Nav
            to="/dashboard/overview"
            className={(isActive) =>
                isActive ? 'active' : ''
            }
        >
            <TYPE.main>Dashboard</TYPE.main>
            <div className="line"></div>
        </Nav> */}
    </>
  );
  return (
    <>
      <HeaderWapper
        className={classNames(
          'header',
          isOpen && 'open',
          headerBgShow && 'showBg',
        )}
      >
        <div className="content">
          {/* <StyledLogo
            src={Logo}
            className="logo"
            onClick={() => {
              history.push('/');
            }}
          /> */}
          <div></div>
          <RightWrpapper>
            <Navs />
            <Language className="language" />
          </RightWrpapper>

          {/* <NavOuterLink style={{ opacity: 0 }}>
            Let’s connect <StyledOuterlinkSVG />
          </NavOuterLink> */}
          <Menu
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            {isOpen ? <CloseIcon /> : <MenuIcon />}
          </Menu>
        </div>
      </HeaderWapper>

      {isOpen && (
        <MobileWrapper>
          <Navs />
          <Language
            onCloseMobileNav={() => {
              setIsOpen(false);
            }}
          />
        </MobileWrapper>
      )}
    </>
  );
}

export default observer(Header);
