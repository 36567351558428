import React, { useMemo } from 'react';
import styled, {
  ThemeProvider as StyledComponentsThemeProvider,
  createGlobalStyle,
  css,
  DefaultTheme,
} from 'styled-components';
import { Text, TextProps } from 'rebass';
import { darken } from 'polished';
import { isMobile } from 'react-device-detect';
import application from 'stores/application';
const MEDIA_WIDTHS = {
  upToExtraSmall: 500,
  upToSmall: 720,
  upToMedium: 960,
  upToLarge: 1280,
  upToMoreLarge: 1440,
};

const mediaWidthTemplates: {
  [width in keyof typeof MEDIA_WIDTHS]: typeof css;
} = Object.keys(MEDIA_WIDTHS).reduce((accumulator, size) => {
  (accumulator as any)[size] = (a: any, b: any, c: any) => css`
    @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
      ${css(a, b, c)}
    }
  `;
  return accumulator;
}, {}) as any;

export function color() {
  return {
    bg01: '#725AF2',
    bg02: '#E7F3FE',
    bg03: '#F3F3F3;',
    bg04: '#E7F3FE;',
    bg05: '#0064C1',

    t01: '#fff',
    t02: '#9F9E9E',
    t03: '#032B43',
    t04: '#3873FF',
  };
}

export function theme(): DefaultTheme {
  return {
    mediaWidth: mediaWidthTemplates,
    c: color(),
  };
}

export const ThemedGlobalStyle = createGlobalStyle`
  html {
    height: 100%;
    width: 100%;
  }
  #root {
    height: 100%;
    width: 100%;
  }
  /* iframe {
    pointer-events: none;
  } */

  * {
    ::-webkit-scrollbar {
        width: 8px;
        height: 100%;
        background-color: transparent;
        border-radius: 8px;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #E7F3FE;
        border-radius: 8px;
    }
  }
  body {
    margin: 0;
    height: 100%;
    width: 100%;
    /* background: #181024; */
  }

  .MuiCalendarPicker-root {
    background: #fff;
    color: #000;

    .MuiSvgIcon-root  {
      fill : #000
    }

    .MuiCalendarPicker-viewTransitionContainer {
      .MuiTypography-root {
        color: #000;
        background-color: transparent;
      }
    }
    .PrivatePickersSlideTransition-root {
      .MuiPickersDay-root {
        background-color: transparent;
        font-weight: 600;
        color: #000;

        &.Mui-selected {
          background: #0064C1;
          border-radius: 8px;
        }
      }

      
    }
   
  }
  .MuiClockPicker-root {
    background: #fff;

    .MuiPickersArrowSwitcher-button {
      &.Mui-disabled {
        svg {
        fill: ${({ theme }) => darken(0.4, theme.c.t02)}
      }
      }
      svg {
        fill: #000
      }
    }
    .css-eziifo {
      background-color: ${({ theme }) => theme.c.bg02}; 
     
      span {
        color: #000;
      }
    }
    .MuiButtonBase-root {
        color: #000;
        .MuiTypography-root {
          color: ${({ theme }) => theme.c.t01};
        }
      }
  }
  .MuiInputBase-root {
    input, textarea {
      font-family: 'Montserrat', sans-serif !important;
    }
  }

  .MuiInputBase-root,.MuiButtonBase-root {
    font-family: 'Montserrat', sans-serif !important;
  }
  
   @font-face{
    font-family: 'gemsbuck-normal';
    src : url(${require('url:assets/font/normal.ttf')});
  } 

  @font-face{
    font-family: 'gemsbuck-title';
    src : url(${require('url:assets/font/regular.ttf')});
  }

  * {
    font-family: "Montserrat", sans-serif;
    box-sizing: border-box;
    }

  .titleFont {
    font-family:  "Montserrat", sans-serif;
  }

  .MuiPopover-paper {
    background: #000;
    .MuiList-root {
    background-color: #000;
    .MuiButtonBase-root {
        color: #fff;

        &.Mui-selected {
            background: linear-gradient(
                90deg,
                rgba(123, 61, 235, 1),
                rgba(47, 208, 238, 1)
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-weight: 600;
            }
        }
    }
  }

`;

export default function ThemeProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const themeObject = useMemo(() => theme(), []);

  return (
    <StyledComponentsThemeProvider theme={themeObject}>
      {children}
    </StyledComponentsThemeProvider>
  );
}

interface TypeProps extends TextProps {
  ellipsis?: boolean;
  wordBreak?: boolean;
  mobileFz?: number;
  uppercase?: boolean;
}

const TextWrapper = styled(Text)<TypeProps>`
  color: ${({ color, theme }) => (theme.c as any)[color as string]};
  width: ${({ ellipsis, wordBreak }) =>
    ellipsis || wordBreak ? '100%' : 'unset'};
  overflow: ${({ ellipsis }) => (ellipsis ? 'hidden' : 'unset')};
  text-overflow: ${({ ellipsis }) => (ellipsis ? 'ellipsis' : 'unset')};
  white-space: ${({ ellipsis }) => (ellipsis ? 'nowrap' : 'unset')};
  word-break: ${({ wordBreak }) => (wordBreak ? 'break-all' : 'unset')};
  text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'unset')};
  &.colorText {
    background: linear-gradient(60.31455181618791deg, #1dc5da 0%, #58297b 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  font-family: 'Montserrat', sans-serif;
  &.mainFont {
    font-family: sans-serif;
  }
`;

export const TYPE = {
  main(props: TypeProps) {
    const fontSize =
      isMobile && props.mobileFz ? props.mobileFz : props.fontSize;
    return (
      <TextWrapper
        fontWeight={500}
        color="t01"
        {...props}
        fontSize={fontSize || 16}
      />
    );
  },
  desc(props: TypeProps) {
    const fontSize =
      isMobile && props.mobileFz ? props.mobileFz : props.fontSize;
    return (
      <TextWrapper
        fontWeight={500}
        color="t02"
        {...props}
        fontSize={fontSize || 14}
      />
    );
  },
  cMain(props: TypeProps) {
    const fontSize =
      isMobile && props.mobileFz ? props.mobileFz : props.fontSize;
    return (
      <TextWrapper
        fontWeight={500}
        className="colorText"
        color="t01"
        {...props}
        fontSize={fontSize || 14}
      />
    );
  },
  gMain(props: TypeProps) {
    const fontSize =
      isMobile && props.mobileFz ? props.mobileFz : props.fontSize;
    return (
      <TextWrapper
        fontWeight={500}
        className="mainFont"
        color="t01"
        {...props}
        fontSize={fontSize || 14}
      />
    );
  },
  gDesc(props: TypeProps) {
    const fontSize =
      isMobile && props.mobileFz ? props.mobileFz : props.fontSize;
    return (
      <TextWrapper
        fontWeight={500}
        className="mainFont"
        color="t02"
        {...props}
        fontSize={fontSize || 14}
      />
    );
  },
  cGMain(props: TypeProps) {
    const fontSize =
      isMobile && props.mobileFz ? props.mobileFz : props.fontSize;
    return (
      <TextWrapper
        fontWeight={500}
        className="colorText mainFont"
        color="t01"
        {...props}
        fontSize={fontSize || 14}
      />
    );
  },
};
