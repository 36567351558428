import classNames from 'classnames';
import {
  MaterialReactTable,
  MaterialReactTableProps,
  MRT_TableInstance,
  MRT_TableOptions,
  useMaterialReactTable,
} from 'material-react-table';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import styled, { createGlobalStyle } from 'styled-components';
import { TYPE } from 'theme';
import MobileTable from './MobileTable';

const Wrapper = styled.div`
  margin-top: 20px;
  &.expandTable {
    .MuiTableContainer-root {
      max-width: 1200px;
      .MuiTableRow-root {
        &:hover td:after {
          background-color: rgba(34, 238, 226, 0.12);
        }
        &:nth-child(4n + 3) {
          :hover td:after {
            background-color: rgba(34, 238, 226, 0.3) !important;
          }
          .MuiTableCell-root {
            background: #000 !important;
          }
        }
        &:nth-child(4n + 2) {
          .MuiTableCell-root {
            background: #1f1f1f !important;
          }
        }
        .MuiTableCell-root {
          &:last-child {
            svg {
              color: #999;
            }
          }
        }
      }
    }
  }
  .MuiPaper-root {
    .MuiTableCell-root {
      font-family: 'Montserrat', sans-serif;
    }
    background: transparent;
    .MuiTableContainer-root {
      > div.MuiBox-root {
        background-color: transparent;
      }
      p {
        &.MuiTypography-root {
          color: #fff;
        }
      }
      .MuiTableHead-root {
        .MuiTableRow-root {
          background: #000;
          border-bottom: 1px solid #fff;

          .MuiTableCell-root {
            background: transparent;
            border: none;
            color: #666666;
            font-weight: bold;
          }
          .MuiSvgIcon-root {
            color: #fff !important;
          }
        }
      }
      .MuiTableBody-root {
        .MuiTableCell-root {
          background: #000;
          color: #fff;
          border: none;
        }
        .MuiTableRow-root {
          &:hover td:after {
            background-color: rgba(34, 238, 226, 0.3);
          }
          &:nth-child(2n + 1) {
            :hover td:after {
              background-color: rgba(34, 238, 226, 0.12);
            }
            .MuiTableCell-root {
              background: #1f1f1f;
            }
          }
        }
      }
    }

    #bottomToolbar {
      z-index: 0;
      background: transparent;
      .MuiBox-root {
        width: 100%;
        background-color: transparent;
        z-index: 0;

        .MuiTablePagination-root {
          .MuiBox-root {
            width: fit-content;
          }
          .MuiFormLabel-root {
            color: ${({ theme }) => theme.c.t02};
          }
          .MuiInputBase-root {
            color: ${({ theme }) => theme.c.t02};
          }
          .MuiSvgIcon-root {
            color: ${({ theme }) => theme.c.t02};
          }
        }

        .MuiPagination-root {
          .MuiButtonBase-root {
            &.Mui-selected {
              background: #22eee2;
              border-color: transparent;
              color: #000;
              font-weight: 600;
              border-radius: 8px;
            }
            color: ${({ theme }) => theme.c.t02};
          }

          .MuiPaginationItem-ellipsis {
            color: ${({ theme }) => theme.c.t02};
          }
        }
      }
    }
  }
`;

const GlobalStyle = createGlobalStyle`
    #bottomPaginationMenu {
        .MuiList-root {
            background-color: #000;
            .MuiButtonBase-root {
                color: #fff;

                &.Mui-selected {
                    background: linear-gradient(
                        90deg,
                        rgba(123, 61, 235, 1),
                        rgba(47, 208, 238, 1)
                    );
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    font-weight: 600;
                }
            }
        }
    }
`;

export interface TableProps {
  table: MRT_TableOptions<any>;
  mobileToolComponent?: React.ReactNode;
}

const Empty = styled.div`
  font-size: 16px;
  padding: 2rem;
  background: #1f1f1f;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  width: 100%;
`;

export default function Table({ table, mobileToolComponent }: TableProps) {
  const { t } = useTranslation();

  const config = {
    renderEmptyRowsFallback: () => <Empty>{t('No records')}</Empty>,
    muiPaginationProps: {
      color: 'primary',
      shape: 'rounded',
      showRowsPerPage: !!table?.data.length,
      variant: 'outlined',
      rowsPerPageOptions: [5, 10, 20],
      SelectProps: {
        MenuProps: {
          id: 'bottomPaginationMenu',
        },
      },
    },
    paginationDisplayMode: 'pages',
    manualPagination: true,
    enableTopToolbar: false,
    enableColumnActions: false,
    positionExpandColumn: 'last',
    muiBottomToolbarProps: {
      id: 'bottomToolbar',
    },
    localization: { rowsPerPage: t('Rows per page'), expand: t('More') },
    ...table,
  };
  return (
    <>
      <GlobalStyle />
      <Wrapper
        className={config?.enableExpandAll !== undefined ? 'expandTable' : ''}
      >
        {isMobile ? (
          <MobileTable
            table={config as any}
            mobileToolComponent={mobileToolComponent}
          />
        ) : (
          <WebTable table={config as any} />
        )}{' '}
      </Wrapper>
    </>
  );
}

function WebTable({ table }: TableProps) {
  const { onPaginationChange, enableExpandAll, state } = table;

  //   function webPaginationChange(paginationState: any) {
  //     console.log(state);
  //     if (onPaginationChange) {
  //       if (enableExpandAll !== undefined && state) {
  //         (state as any).expanded = undefined;
  //       }
  //       onPaginationChange(paginationState);
  //     }
  //   }
  //   const mTable = useMaterialReactTable({
  //     ...table,
  //     state: {
  //       ...state,
  //     },
  //     onPaginationChange: webPaginationChange,
  //   });
  const mTable = useMaterialReactTable(table);

  return <MaterialReactTable table={mTable} />;
}
